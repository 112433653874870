<template>
  <div class="root_store">
    <Navbar value='2' />
    <div class="root_howit">
      <img src="../../assets/yicimages/howitworks.png" class="lglg" />
      <img src="../../assets/yicimages/howitworksmobile.png" class="smsm" />
    </div>
    <FooterYic />
  </div>
</template>
<script>
import Navbar from "../navbar/Navbar.vue";
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import FooterYic from '../homepage/footer.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    FooterYic,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    Navbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  mounted() {
    this.setNavbarRoute("howyicworks");
  },
  methods: {
    ...mapMutations({
      setNavbarRoute: "routesStore/SET_NAVBAR",
    }),
    onClick() {
      this.$router.push({ name: 'store' });
    },
  },
};
</script>
<style scoped>
.root_howit {
  width: 100vw;
  height: 100vh;
}

.lglg {
  width: 100%;
  height: 100%;
}

.smsm {
  display: none;
}


/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
  .lglg {
    display: none;

  }

  .smsm {
    display: unset;
    width: 100%;
    height: 100%;
  }

}
</style>